import {UserAvatarLink_UserFragment} from './__generated__/UserAvatarLink.graphql';
import navAvatarDescription from '../../../../__generated__/intl/strings/nav/navAvatarDescription';
import myPageTitle from '../../../../__generated__/intl/strings/pages/myPage/myPageTitle';
import {CSSStyles} from '../../../types/CSSStyles';
import {linkToAppWithParams} from '../../../url/linkToApp';
import BackgroundImageDiv from '../../Image/BackgroundImageDiv';
import Flex from '../../layout/flex/Flex';
import UnstyledButton from '../Button/UnstyledButton';
import useAppTheme from '../hooks/useAppTheme';
import useMediaQueryGetter, {MediaQuery} from '../hooks/useMediaQueryGetter';
import User from '../icon/User';
import LinkText from '../typography/LinkText';
import UtilityText from '../typography/UtilityText';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly user: UserAvatarLink_UserFragment;
  /**
   * @default false
   */
  readonly showUserDetail?: boolean;
  readonly sx?: CSSStyles;
  /**
   * If not set, it will link to the member profile page.
   */
  readonly to?: string;
}

export default function UserAvatarLink(props: Props): JSX.Element {
  const {showUserDetail, sx, to, user} = props;
  const publicProfile = user?.publicProfile;
  const photoUrl = publicProfile?.photoDownloadUrl;
  const {palettes, spacing} = useAppTheme();
  const mq = useMediaQueryGetter();

  let userInfo = null;
  if (showUserDetail && publicProfile != null) {
    userInfo = (
      <Flex
        css={css({
          alignItems: 'flex-start',

          [mq(MediaQuery.LargeAndExtraLarge)]: {
            display: 'none',
          },
        })}
        direction="column"
      >
        <LinkText>
          <span>{publicProfile.firstName}</span>
          <span> </span>
          <span>{publicProfile.lastName}</span>
        </LinkText>
        <UtilityText>{navAvatarDescription()}</UtilityText>
      </Flex>
    );
  }

  return (
    <UnstyledButton
      label={myPageTitle()}
      labelIsHidden={true}
      sx={[
        css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: spacing.x20,
          padding: 0,
          overflow: 'hidden',
          border: 'none',
        }),
        sx,
      ]}
      to={to ?? linkToAppWithParams('/member/:id', {id: user?.id})}
    >
      {photoUrl == null ? (
        <Flex
          css={css({
            justifyContent: 'center',
            overflow: 'hidden',
            borderRadius: '50%',
            backgroundColor: palettes.background.neutral.disabled,
            height: 48,
            width: 48,

            // [mq(MediaQuery.SmallAndMedium)]: {
            //   height: 64,
            //   width: 64,
            // },
          })}
        >
          <User size={35} />
        </Flex>
      ) : (
        <BackgroundImageDiv
          backgroundSize="cover"
          src={photoUrl}
          sx={css({
            borderRadius: '50%',
            height: 48,
            width: 48,

            // [mq(MediaQuery.SmallAndMedium)]: {
            //   height: 64,
            //   width: 64,
            // },
          })}
        />
      )}

      {userInfo}
    </UnstyledButton>
  );
}
