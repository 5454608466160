import {CSSStyles} from '../../../types/CSSStyles';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly sx?: CSSStyles;
}

export default function Gap(props: Props): JSX.Element {
  const {sx} = props;
  return (
    <div
      css={[
        css({
          flex: 1,
        }),
        sx,
      ]}
    />
  );
}
