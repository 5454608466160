import Link, {Props as LinkProps} from './Link';
import useContentPalette from '../theme/useContentPalette';
import LinkText, {Props as TextProps} from '../typography/LinkText';

import {css} from '@emotion/react';
import React from 'react';

export interface Props extends LinkProps {
  textProps?: TextProps;
}

export default function TextLink(props: Props): JSX.Element {
  const {children, sx: rootStyle, textProps, ...linkProps} = props;
  const {color, sx: textRootStyle, ...otherTextProps} = textProps ?? {};
  const contentPalette = useContentPalette(color);

  return (
    <Link
      {...linkProps}
      sx={[
        css({
          borderRadius: '5px',
          textDecoration: 'none',
        }),
        rootStyle,
      ]}
    >
      <LinkText
        sx={[
          css({
            display: 'flex',
            alignItems: 'center',
            color: contentPalette.default,

            '&:active': {
              color: contentPalette.active,
            },
            '&:hover': {
              color: contentPalette.hover,
            },
          }),
          textRootStyle,
        ]}
        {...otherTextProps}
      >
        {children}
      </LinkText>
    </Link>
  );
}
